// import bootstrap
import * as bootstrap from 'bootstrap'

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl))

// import glightbox
import GLightbox from './glightbox.min.js'

let customSlideHTML = `<div class="gslide">
<div class="gslide-inner-content">
<div class="ginner-container">
<div class="gslide-media">
</div>
<div class="gslide-description">
<div class="[ bg-gradient-danger ] gdesc-inner">
<h6 class="[ fw-bold text-info text-gradient mb-2 ] gslide-title"></h6>
<div class="[ text-light ] gslide-desc"></div>
</div>
</div>
</div>
</div>
</div>`

const lightbox = GLightbox({ selector: '.glightbox', slideHTML: customSlideHTML })
